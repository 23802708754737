import {useApiActions} from './index'
import {
	ClientResponseModel,
	MemberDataModel,
	UploadFilter,
	UploadTransaction,
	UploadTransactionBulkResponse
} from '../../types/upload'
import {Currency, Timezone, UploadActionType} from '../../types'
import axios from 'axios'

const interval = process.env.REACT_APP_IMPORTCSV_RECHECK_INTERVAL;

export const useUploadApi = () => {
	const {callApi} = useApiActions('upload')

	const getActionTypes = async (filter: UploadFilter): Promise<UploadActionType[]> => {
		return await callApi<UploadActionType[], string>({
			query: filter,
			path: 'action-types'
		})
	}

	const getCurrencies = async (filter: UploadFilter): Promise<Currency[]> => {
		return await callApi<Currency[], string>({
			query: filter,
			path: 'currencies'
		})
	}

	const getErrorCsvAsync = async (filter: UploadFilter): Promise<any> => {
		return await callApi<any, string>({
			query: filter,
			path: 'error-csv'
		})
	}

	const getMembers = async (filter: UploadFilter): Promise<MemberDataModel[]> => {
		return await callApi<MemberDataModel[], string>({
			query: filter,
			path: 'members'
		})
	}

	const getTimezones = async (filter: UploadFilter): Promise<Timezone[]> => {
		return await callApi<Timezone[], string>({
			query: filter,
			path: 'timezones'
		})
	}

	const uploadTransaction = async (data: UploadTransaction, filter: UploadFilter): Promise<ClientResponseModel> => {
		return await callApi<ClientResponseModel, UploadTransaction>({
			method: 'POST',
			query: filter,
			requestData: data,
			path: 'transaction'
		})
	}

	const uploadTransactionCsv = async (data: FormData, filter: UploadFilter): Promise<UploadTransactionBulkResponse> => {
		const postResponse = await callApi<any, FormData>({
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			method: 'POST',
			query: filter,
			requestData: data,
			path: 'transaction-csv'
		})

		let outputResponse;
		do {
			const { data } = await axios.get(postResponse.getStatusQueryUri);

			if (data && data.runtimeStatus === "Completed") {
				outputResponse = data.output.Value;
				break;
			}

			await new Promise(resolve => setTimeout(resolve, 1000 * Number(interval ?? 10)));

		} while (true);

		return outputResponse;
	}

	return {
		getActionTypes,
		getCurrencies,
		getErrorCsvAsync,
		getMembers,
		getTimezones,
		uploadTransaction,
		uploadTransactionCsv
	}
}
