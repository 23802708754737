import React, {useState} from 'react'
import ConfirmModal from '../../../components/Modal/ConfirmModal'
import {
	useRecoilState,
	useRecoilValue,
	useSetRecoilState
} from 'recoil'
import {
	selectedActionTypeUpload,
	selectedCurrencyUpload,
	selectedMemberUpload,
	selectedProductTypeUpload,
	uploadConfirmModalOpen
} from '../../../state/upload-data'
import {
	rpStandardProduct,
	UploadTransaction,
} from '../../../types/upload'
import {loggedInUserState} from '../../../state/auth'
import {notificationMessageState} from '../../../state/ui'
import {NotificationMessageType} from '../../../types'
import {useUploadApi} from '../../../hooks/api/upload'
import {uploadFilterState} from '../../../state/upload-filter'

interface ConfirmUploadTransactionModalProps {
	uploadFormData: UploadTransaction
	reset(): void
}

const successMessage = 'Transaction Uploaded Successfully.'

const uploadTransactionTexts = {
	title: 'Upload Transaction',
	body: 'You are about to upload this transaction, are you sure?'
}

const ConfirmUploadTransactionModal = (props: ConfirmUploadTransactionModalProps) => {

	const [loadingState, setLoadingState] = useState<boolean>(false)

	const [openConfirmUploadTransactionState, setOpenConfirmUploadTransactionState] = useRecoilState(uploadConfirmModalOpen)

	const uploadCurrencyCode = useRecoilValue(selectedCurrencyUpload)
	const productType = useRecoilValue(selectedProductTypeUpload)
	const loggedInUser = useRecoilValue(loggedInUserState)
	const uploadFilter = useRecoilValue(uploadFilterState)
	const actionType = useRecoilValue(selectedActionTypeUpload)
	const member = useRecoilValue(selectedMemberUpload)
	const setNotification = useSetRecoilState(notificationMessageState)
	const {uploadTransaction} = useUploadApi()

	async function handleConfirm() {
		try {
			const uploadData: UploadTransaction = {
				actionType: actionType,
				actorId: member?.actorId,
				customerFirstName: props.uploadFormData.customerFirstName.trim(),
				customerLastName: props.uploadFormData.customerLastName.trim(),
				loggedInUser: loggedInUser,
				productCode: props.uploadFormData.productCode,
				salesReferenceId: props.uploadFormData.salesReferenceId.trim(),
				sold: props.uploadFormData.sold,
				timezoneId: props.uploadFormData.timezoneId,
				uploadProductType: productType,
				vendorSalesDateTime: props.uploadFormData.vendorSalesDateTime.trim(),
			}

			if (productType === rpStandardProduct?.value) {
				uploadData.currencyCode = uploadCurrencyCode
				uploadData.eventDateTime = props.uploadFormData.eventDateTime?.trim()
				uploadData.offeringMethod = props.uploadFormData.offeringMethod
				uploadData.premiumRate = props.uploadFormData.premiumRate
				uploadData.productPrice = props.uploadFormData.productPrice
			} else {
				uploadData.quoteId = props.uploadFormData.quoteId?.trim()
			}

			setLoadingState(true)
			const response = await uploadTransaction(uploadData, uploadFilter)

			if (response.processed) {
				setNotification({
					message: `${response.message ? `${successMessage}, SaleId: ${response.message}` : successMessage}`,
					type: NotificationMessageType.success
				})
				return
			}

			setNotification({
				message: `${response.message}`,
				type: NotificationMessageType.error
			})

		} catch (error: any) {
			setNotification({
				message: `${error}`,
				type: NotificationMessageType.error
			})
		} finally {
			setOpenConfirmUploadTransactionState(false)
			props.reset()
			setLoadingState(false)
		}
	}

	const handleClose = () => {
		setOpenConfirmUploadTransactionState(false)
	}

	return (
		<ConfirmModal
			titleText={uploadTransactionTexts.title}
			bodyText={uploadTransactionTexts.body}
			isOpen={openConfirmUploadTransactionState}
			onConfirm={handleConfirm}
			onCancel={handleClose}
			isDisabled={loadingState}
		/>
	)
}

export default ConfirmUploadTransactionModal
