import React from 'react'
import SecondaryValueCard from './Common/Cards/SecondaryValueCard'
import {usePulseApi} from '../../../hooks/api/pulse'
import {useApiValue} from '../../../hooks/api'
import {filterState} from '../../../state/pulse'
import {Percentage} from './Common/FormatElements'
import {FilterStateProps} from '../../../types/pulse-data'

const OverviewConversionRateSummary = (props: FilterStateProps) => {
	const {getAverageConversionRate} = usePulseApi()
	const value = useApiValue(props.filterState || filterState, getAverageConversionRate, 0)

	return (
		<SecondaryValueCard
			value={<Percentage value={value.data}/>}
			header="Conversion Rate"
			tooltipText="This is the average percentage of customers who opt into Refund Protect relative to the total Gross Booking Volume (GBV).
				It represents the ratio between the number of bookings where Refund Protect is selected and the overall number of bookings,
				helping to assess how effectively the Refund Protect option is being adopted by customers."
			isLoading={value.isLoading}
		/>
	)
}

export default OverviewConversionRateSummary
