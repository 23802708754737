import {ChartDataset, ChartOptions, TooltipItem} from 'chart.js'
import {PulseVerticalsRevenueTableDataModel} from '../../../types/pulse-data'
import {formatCompactCurrency, formatCurrency} from '../../../utils/currency'
import {useRecoilValue} from 'recoil'
import {selectedCoreCurrencyState} from '../../../state/pulse-filters'
import {verticalsRevenueTableBreakdownColumnState} from '../../../state/pulse'
import {
	colorTheme,
	verticalsRevenueTableBreakdownColumn,
	verticalsRevenueTableBreakdownColumnName
} from '../../../types/pulse'

export default function useGetVerticalsRevenueBarChartData(recoilValue: { data: PulseVerticalsRevenueTableDataModel, isLoading: boolean }) {
	const coreCurrency = useRecoilValue(selectedCoreCurrencyState)
	const breakdownColumn = useRecoilValue(verticalsRevenueTableBreakdownColumnState)

	const getOptions = () => {
		const options: ChartOptions<'bar'> = {
			indexAxis: 'y',
			plugins: {
				legend: {
					display: false
				},
				tooltip: {
					bodySpacing: 8,
					boxPadding: 4,
					callbacks: {
						label: (tooltip: TooltipItem<'bar'>) => {
							return `${verticalsRevenueTableBreakdownColumnName[breakdownColumn]}: ` +
								`${formatCurrency(coreCurrency, tooltip.raw as any, 0, 0)}`
						}
					},
					enabled: true,
					mode: 'index',
					padding: 16,
					titleMarginBottom: 8
				}
			},
			scales: {
				x: {
					display: true,
					position: 'left',
					ticks: {
						callback: function (value) {
							return formatCompactCurrency(coreCurrency, parseFloat(value.toString()))
						}
					},
					type: 'linear'
				},
				y: {
					grid: {
						display: false
					}
				}
			}
		}

		return options
	}

	const getLabels = () =>
		(recoilValue.data.verticals && recoilValue.data.verticals.map(vertical => vertical.verticalName))
		|| []

	const getDatasets = () => {
		const datasets: ChartDataset<'bar'>[] = []

		if (!recoilValue.data.verticals) {
			return datasets
		}

		const data: number[] = []

		for (let i = 0; i < recoilValue.data.verticals.length; i++) {
			const vertical = recoilValue.data.verticals[i]

			switch (breakdownColumn) {
				case verticalsRevenueTableBreakdownColumn.RefundProtectValue:
					data.push(vertical.refundProtectValue || 0)
					break
				case verticalsRevenueTableBreakdownColumn.ProtectionValue:
					data.push(vertical.protectionValue || 0)
					break
				case verticalsRevenueTableBreakdownColumn.ApprovedRefundsValue:
					data.push(vertical.approvedRefundsValue || 0)
					break
				case verticalsRevenueTableBreakdownColumn.ProfitValue:
					data.push(vertical.profitValue || 0)
					break
			}

		}

		datasets.push({
			backgroundColor: colorTheme,
			data
		})

		return datasets
	}

	return {
		getDatasets,
		getLabels,
		getOptions
	}
}
