import React, {useEffect, useState} from 'react'
import {useRecoilState, useRecoilValue} from 'recoil'
import {selectedProductIdState} from '../../../../../state/pulse-filters'
import {Listbox, ListboxButton, ListboxOption, ListboxOptions} from '@headlessui/react'
import {productsState, userMemberHasCFAR} from '../../../../../state/pulse'
import {Product} from '../../../../../types/pulse-data'
import {productCFAR} from '../../../../../utils/data'
import classNames from 'classnames'

const ProductFilter = () => {
	const products = useRecoilValue(productsState)
	const [productId, setProductId] = useRecoilState(selectedProductIdState)
	const [filteredProducts, setFilteredProducts] = useState<Product[]>([])
	const userHasCFAR = useRecoilValue(userMemberHasCFAR)

	useEffect(() => {
		setFilteredProducts(products.filter(x => x.id === productId))
	}, [productId, products])

	return (
		<Listbox value={productId} onChange={setProductId}>
			<ListboxButton
				className={classNames({
					'opacity-40': !userHasCFAR
				})}
				disabled={!userHasCFAR}
			>
				<div className="
					mb-2 pl-3 pr-2 py-[2px]
					bg-white
					border border-pulse-icon-gray rounded-full
					text-nowrap text-sm tracking-[1px] uppercase
					cursor-pointer
					hover:bg-pulse-button-blue
					lg:mb-0"
				>
					{!productId && (<span className="leading-5">Refund Protect</span>)}
					{filteredProducts && filteredProducts.length > 0 && (
						<span className="leading-5">{filteredProducts[0].name}</span>
					)}
					<span className="
						align-middle
						leading-5
						material-symbols-outlined"
					>
						keyboard_arrow_down
					</span>
				</div>
			</ListboxButton>
			<ListboxOptions
				className="
					h-full min-w-[var(--button-width)] mt-[5px] py-2 z-50
					bg-white
					text-sm tracking-[1px] uppercase
					border border-pulse-popup-border-gray rounded-lg
					lg:w-auto
					[--anchor-max-height:12rem]"
				anchor="bottom end"
			>
				<ListboxOption
					key={0}
					value={null}
					className="
						px-6 py-1
						cursor-pointer
						data-[focus]:bg-pulse-button-blue"
				>
					Refund Protect
				</ListboxOption>
				{products
					.filter(product => product.code === productCFAR)
					.map((product) => (
							<ListboxOption
								key={product.id}
								value={product.id}
								className="
									max-w-48 px-6 py-1
									cursor-pointer
									data-[focus]:bg-pulse-button-blue"
							>
								{product.name}
							</ListboxOption>
						)
					)}
			</ListboxOptions>
		</Listbox>
	)
}

export default ProductFilter
