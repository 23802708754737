import React, {useEffect} from 'react'
import DashboardPageLayout from './components/DashboardPageLayout'
import DashboardSupport from './components/DashboardSupport'
import {useRecoilValue, useSetRecoilState} from 'recoil'
import {
	exportDataState,
	memberTransactionsOutOfRangeState,
	titleSuffixState
} from '../../state/pulse'
import RevenueTrendsGraph from './components/RevenueTrendsGraph'
import useExportRevenueTrendsData from './hooks/useExportRevenueTrendsData'
import DataAvailabilityWarningOverlay from './components/DataAvailabilityWarningOverlay'
import H2 from './components/Common/Headers/H2'

const PulseRevenueTrendsView = () => {
	const exportData = useExportRevenueTrendsData()
	const titleSuffix = useRecoilValue(titleSuffixState)
	const memberTransactionsOutOfRange = useRecoilValue(memberTransactionsOutOfRangeState)
	const setExportDataState = useSetRecoilState(exportDataState)

	useEffect(() => {
		setExportDataState(exportData)
	}, [exportData])

	return (
		<DashboardPageLayout>
			<>
				<section className="
					max-w-6xl px-4 w-full
					lg:mx-auto lg:px-10"
				>
					{!memberTransactionsOutOfRange && (<RevenueTrendsGraph/>)}
					{memberTransactionsOutOfRange && (
						<>
							<H2>Revenue Trends - {titleSuffix}</H2>
							<DataAvailabilityWarningOverlay/>
						</>
					)}
					<div className="pt-8">
						<DashboardSupport/>
					</div>
				</section>
			</>
		</DashboardPageLayout>
	)
}

export default PulseRevenueTrendsView
