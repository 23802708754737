import {atom, selector} from 'recoil'
import {
	batchAction,
	dateRange,
	graphFilterType,
	partnersRevenueTablePricingModel,
	partnersRevenueTableSortColumn,
	PulseFilter,
	PulsePartnersRevenueTableFilter,
	PulseTableFilter,
	PulseUserFilter,
	PulseVerticalsRevenueTableFilter,
	verticalsRevenueTableBreakdownColumn,
	verticalsRevenueTableSortColumn
} from '../types/pulse'
import {
	dateFromState,
	formattedDateFromState,
	formattedDateToState,
	selectedCoreCurrencyState,
	selectedDateRangeState,
	selectedGraphFiltersState,
	selectedMemberIdState,
	selectedProductIdState,
	selectedRegionIdState,
	selectedVerticalIdState
} from './pulse-filters'
import {loggedInUserState} from './auth'
import {
	ExportData,
	Product,
	SalesRegion,
	SupportDetail,
	TableRow,
	UserDetail,
	Vertical
} from '../types/pulse-data'
import moment from 'moment'
import {Member} from '../types'
import {productCFAR} from '../utils/data'

export type ValuePiece = Date | null;
export type Value = ValuePiece | [ValuePiece, ValuePiece];

const keyPrefix = 'pulse'

export const filterState = selector<PulseFilter>({
	key: `${keyPrefix}-filter`,
	get: ({get}) => {
		return {
			dateFrom: get(formattedDateFromState),
			dateTo: get(formattedDateToState),
			memberId: get(selectedMemberIdState),
			regionId: get(selectedRegionIdState),
			userName: get(loggedInUserState),
			coreCurrencyCode: get(selectedCoreCurrencyState),
			dateRange: get(selectedDateRangeState).toString(),
			graphFilters: get(selectedGraphFiltersState),
			productId: get(selectedProductIdState),
			verticalId: get(selectedVerticalIdState)
		}
	}
})

export const selectedRegionFilterState = selector<graphFilterType | undefined>({
	key: `${keyPrefix}-selectedRegionFilter`,
	get: ({get}) => {
		const graphFilters = get(selectedGraphFiltersState)

		if (graphFilters.includes(graphFilterType.RefundProtectValue)) {
			return graphFilterType.RefundProtectValue
		}

		if (graphFilters.includes(graphFilterType.GrossBookingValue)) {
			return graphFilterType.GrossBookingValue
		}

		if (graphFilters.includes(graphFilterType.AverageConversionRate)) {
			return graphFilterType.AverageConversionRate
		}

		if (graphFilters.includes(graphFilterType.GrossBookingVolume)) {
			return graphFilterType.GrossBookingVolume
		}

		if (graphFilters.includes(graphFilterType.RevenuePerDollar)) {
			return graphFilterType.RevenuePerDollar
		}

		return undefined
	}
})

export const tableFilterState = selector<PulseTableFilter>({
	key: `${keyPrefix}-tableFilter`,
	get: ({get}) => {
		return {
			...get(filterState),
			page: get(tablePageState),
			pageSize: get(tablePageSizeState),
			sortColumn: get(tableSortColumnState),
			sortDirection: get(tableSortDirectionState),
			search: get(tableSearchState),
			isInsured: get(tableIsInsuredState),
			autoRefresh: get(transactionTableUpdateTriggerState)
		}
	}
})

export const scrollState = atom<boolean>({
	key: `${keyPrefix}-scroll`,
	default: false
})

export const calendarDatesState = atom<Value>({
	key: `${keyPrefix}-calendarDates`,
	default: null
})

export const tablePageState = atom<number>({
	key: `${keyPrefix}-tablePage`,
	default: 1
})

export const tablePageSizeState = atom<number>({
	key: `${keyPrefix}-tablePageSize`,
	default: 10
})

export const tableSortColumnState = atom<string>({
	key: `${keyPrefix}-tableSortColumn`,
	default: 'transactionId'
})

export const tableSortDirectionState = atom<string>({
	key: `${keyPrefix}-tableSortDirection`,
	default: 'desc'
})

export const tableSearchState = atom<string>({
	key: `${keyPrefix}-tableSearch`,
	default: ''
})

export const tableIsInsuredState = atom<boolean>({
	key: `${keyPrefix}-tableIsInsured`,
	default: true
})

export const transactionTableUpdateTriggerState = atom<number>({
	key: `${keyPrefix}-transactionTableUpdateTrigger`,
	default: 0
})

export const toastTextState = atom<string>({
	key: `${keyPrefix}-toastText`,
	default: ''
})

export const selectedTransactionsState = atom<number[]>({
	key: `${keyPrefix}-selectedTransactions`,
	default: []
})

export const transactionBatchActionState = atom<batchAction>({
	key: `${keyPrefix}-transactionBatchAction`,
	default: batchAction.None
})

export const selectedTransactionState = atom<TableRow | null>({
	key: `${keyPrefix}-selectedTransaction`,
	default: null
})

export const exportDataState = atom<ExportData | null>({
	key: `${keyPrefix}-exportData`,
	default: null
})

export const userDetailState = atom<UserDetail>({
	key: `${keyPrefix}-userDetail`,
	default: {}
})

export const supportDetailState = atom<SupportDetail>({
	key: `${keyPrefix}-supportDetail`,
	default: {}
})

export const membersState = atom<Member[]>({
	key: `${keyPrefix}-members`,
	default: []
})

export const salesRegionsState = atom<SalesRegion[]>({
	key: `${keyPrefix}-salesRegions`,
	default: []
})

export const titleSuffixState = selector<string>({
	key: `${keyPrefix}-titleSuffix`,
	get: ({get}) => {
		const currentDate = moment()
		const date = get(selectedDateRangeState)
		const fromDate = get(dateFromState)
		const formattedFromDate = get(formattedDateFromState)
		const formattedToDate = get(formattedDateToState)

		switch (date) {
			case dateRange.YearToDate:
				return `YTD ${currentDate.format('YYYY')}`
			case dateRange.Year:
				return currentDate.add(-1, 'year').format('YYYY')
			case dateRange.Custom:
				return `${formattedFromDate} - ${formattedToDate}`
			case dateRange.Month:
				return fromDate?.format('MMMM YY') || ''
			case dateRange.AllTime:
				return 'All Time'
			default:
				return ''
		}
	}
})

export const userFilterState = selector<PulseUserFilter>({
	key: `${keyPrefix}-userFilter`,
	get: ({get}) => {
		return {
			userName: get(loggedInUserState)
		}
	}
})

export const dashboardLoadingState = atom<boolean>({
	key: `${keyPrefix}-dashboardLoading`,
	default: true
})

export const overviewChartFilterState = selector<PulseFilter>({
	key: `${keyPrefix}-overviewChartFilter`,
	get: ({get}) => {
		return {
			...get(filterState),
			graphFilters: [
				graphFilterType.YourRevenue,
				graphFilterType.AverageConversionRate,
				graphFilterType.GrossBookingVolume,
				graphFilterType.RefundProtectVolume
			]
		}
	}
})

export const memberTransactionsOutOfRangeState = atom<boolean>({
	key: `${keyPrefix}-memberTransactionsOutOfRange`,
	default: false
})

export const overviewLeadershipRefundChartFilterState = selector<PulseFilter>({
	key: `${keyPrefix}-overviewLeadershipRefundChartFilter`,
	get: ({get}) => {
		return {
			...get(filterState),
			graphFilters: [
				graphFilterType.ProtectGroupProfit,
				graphFilterType.ApprovedRefunds,
				graphFilterType.RefundProtectValue
			]
		}
	}
})

export const partnersRevenueTableSortColumnState = atom<partnersRevenueTableSortColumn>({
	key: `${keyPrefix}-partnersRevenueTableSortColumn`,
	default: partnersRevenueTableSortColumn.ProtectionValue
})

export const partnersRevenueTablePageState = atom<number>({
	key: `${keyPrefix}-partnersRevenueTablePage`,
	default: 1
})

export const partnersRevenueTablePricingModelState = atom<partnersRevenueTablePricingModel>({
	key: `${keyPrefix}-partnersRevenueTablePricingModel`,
	default: partnersRevenueTablePricingModel.All
})

export const partnersRevenueTableFilterState = selector<PulsePartnersRevenueTableFilter>({
	key: `${keyPrefix}-partnersRevenueTableFilter`,
	get: ({get}) => {
		return {
			...get(filterState),
			page: get(partnersRevenueTablePageState),
			pageSize: get(tablePageSizeState),
			sortColumn: get(partnersRevenueTableSortColumnState),
			autoRefresh: get(transactionTableUpdateTriggerState),
			pricingModel: get(partnersRevenueTablePricingModelState)
		}
	}
})

export const overviewLeadershipTopPartnersTableFilterState = selector<PulsePartnersRevenueTableFilter>({
	key: `${keyPrefix}-overviewLeadershipTopPartnersTableFilter`,
	get: ({get}) => {
		return {
			...get(partnersRevenueTableFilterState),
			page: 0,
			pageSize: 20,
			sortColumn: partnersRevenueTableSortColumn.ProtectionValue,
			pricingModel: partnersRevenueTablePricingModel.All
		}
	}
})

export const verticalsRevenueTableSortColumnState = atom<verticalsRevenueTableSortColumn>({
	key: `${keyPrefix}-verticalsRevenueTableSortColumn`,
	default: verticalsRevenueTableSortColumn.ProtectionValue
})

export const verticalsRevenueTableFilterState = selector<PulseVerticalsRevenueTableFilter>({
	key: `${keyPrefix}-verticalsRevenueTableFilter`,
	get: ({get}) => {
		return {
			...get(filterState),
			sortColumn: get(verticalsRevenueTableSortColumnState),
			autoRefresh: get(transactionTableUpdateTriggerState)
		}
	}
})

export const verticalsRevenueTableBreakdownColumnState = atom<verticalsRevenueTableBreakdownColumn>({
	key: `${keyPrefix}-verticalsRevenueTableBreakdownColumn`,
	default: verticalsRevenueTableBreakdownColumn.ProtectionValue
})

export const productsState = atom<Product[]>({
	key: `${keyPrefix}-products`,
	default: []
})

export const userMemberHasCFAR = selector<boolean>({
	key: 'userHasRoleState',
	get: ({get}) => {
		const products = get(productsState)
		return products.some(x => x.code === productCFAR)
	}
})

export const verticalsState = atom<Vertical[]>({
	key: `${keyPrefix}-verticals`,
	default: []
})

export const verticalsPageVerticalState = atom<number | null>({
	key: `${keyPrefix}-verticalsPageVertical`,
	default: null
})

export const verticalsPageFilterState = selector<PulseFilter>({
	key: `${keyPrefix}-verticalsPageFilter`,
	get: ({get}) => {
		return {
			...get(filterState),
			verticalId: get(verticalsPageVerticalState)
		}
	}
})

export const verticalsPageOverviewChartFilterState = selector<PulseFilter>({
	key: `${keyPrefix}-verticalsPageOverviewChartFilter`,
	get: ({get}) => {
		return {
			...get(overviewChartFilterState),
			verticalId: get(verticalsPageVerticalState)
		}
	}
})

export const pageAnalyticsTablePageState = atom<number>({
	key: `${keyPrefix}-pageAnalyticsTablePage`,
	default: 1
})

export const enableFiltersState = atom<boolean>({
	key: `${keyPrefix}-enableFilters`,
	default: true
})