import React, {memo, useEffect, useState} from 'react'
import {TableCard} from '../../../components/Card/TableCard'
import {useRecoilValue} from 'recoil'
import DataTable from 'react-data-table-component'
import {
	commissionFilterState,
	commissionSummaryTableColumnsState,
	commissionTableColumnsState,
} from '../../../state/commission-data'
import {useCommissionRevenueToggles} from './CommissionRevenueToggles'
import {useCommissionApi} from '../../../hooks/api/commission'
import {useApiValue} from '../../../hooks/api'

const RpCommissionTable = memo(() => {
	const [showPaging, setShowPaging] = useState<boolean>(false)
	const {selectedToggle, toggleComponent} = useCommissionRevenueToggles()
	const columns = useRecoilValue(commissionTableColumnsState(selectedToggle))
	const summaryColumns = useRecoilValue(commissionSummaryTableColumnsState(selectedToggle))
	const {getRpCommissionData} = useCommissionApi()
	const {data: tableData} = useApiValue(commissionFilterState, getRpCommissionData, {
		data: [],
		total: []
	})

	useEffect(() => {
		setShowPaging(tableData.data.length > 10)
	}, [tableData])

	return (
		<TableCard
			title="Refund Protect"
			titleClass="card-title-rp"
			showToggle={true}
			headerToggle={toggleComponent}
		>
			<>
				<DataTable
					data={tableData.data}
					columns={columns}
					theme="dark"
					pagination={showPaging}
				/>

				<DataTable
					columns={summaryColumns}
					data={[tableData.total]}
					theme="dark"
				/>
			</>
		</TableCard>

	)
})

export {RpCommissionTable}
