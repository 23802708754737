import React from 'react'
import {partnersRevenueTableSortColumn, partnersRevenueTableSortColumnName} from '../../../../../types/pulse'
import {useRecoilState, useResetRecoilState} from 'recoil'
import {partnersRevenueTablePageState, partnersRevenueTableSortColumnState} from '../../../../../state/pulse'
import {Listbox, ListboxButton, ListboxOption, ListboxOptions} from '@headlessui/react'

const PartnersSortColumnDropdown = () => {
	const [sortColumn, setSortColumn] = useRecoilState(partnersRevenueTableSortColumnState)
	const resetPageState = useResetRecoilState(partnersRevenueTablePageState)

	const handleClick = (value: partnersRevenueTableSortColumn) => {
		setSortColumn(value)
		resetPageState()
	}

	return (
		<Listbox value={sortColumn} onChange={(value) => handleClick(value)}>
			<ListboxButton>
				<div className="
					mb-2 pl-3 pr-2 py-[2px]
					bg-pulse-button-blue
					text-nowrap text-sm uppercase
					border border-pulse-icon-gray rounded-full
					cursor-pointer
					lg:mb-0"
				>
					<span className="leading-5 tracking-[1px]">{partnersRevenueTableSortColumnName[sortColumn]}</span>
					<span className="
						align-middle ml-1
						leading-5
						material-symbols-outlined"
					>
						keyboard_arrow_down
					</span>
				</div>
			</ListboxButton>
			<ListboxOptions
				className="
					min-w-[var(--button-width)] ml-4 mt-[5px] py-2 w-11/12 z-50
					bg-white
					border border-pulse-popup-border-gray rounded-lg
					lg:w-auto lg:ml-0"
				anchor="bottom start"
			>
				<DropdownOption sortColumn={partnersRevenueTableSortColumn.MemberName}/>
				<DropdownOption sortColumn={partnersRevenueTableSortColumn.ProtectionValue}/>
				<DropdownOption sortColumn={partnersRevenueTableSortColumn.RefundProtectValue}/>
				<DropdownOption sortColumn={partnersRevenueTableSortColumn.ProfitValue}/>
				<DropdownOption sortColumn={partnersRevenueTableSortColumn.ApprovedRefundsValue}/>
			</ListboxOptions>
		</Listbox>
	)
}

const DropdownOption = (props: {sortColumn: partnersRevenueTableSortColumn}) => {
	return (
		<ListboxOption
			value={props.sortColumn}
			className="
				px-4 py-2
				text-sm text-black tracking-[1px] uppercase
				cursor-pointer
				data-[focus]:bg-pulse-button-blue"
		>
			{partnersRevenueTableSortColumnName[props.sortColumn]}
		</ListboxOption>
	)
}

export default PartnersSortColumnDropdown
