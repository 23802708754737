import React from 'react'
import PrimaryVolumeCard from './Common/Cards/PrimaryVolumeCard'
import {usePulseApi} from '../../../hooks/api/pulse'
import {useApiValue} from '../../../hooks/api'
import {filterState} from '../../../state/pulse'

const OverviewProtectGroupProfitValueSummary = () => {
	const {getLossRatioRate, getLossRatioTrend} = usePulseApi()
	const value = useApiValue(filterState, getLossRatioRate, 0)
	const trendRate = useApiValue(filterState, getLossRatioTrend, 0)

	return (
		<PrimaryVolumeCard
			value={<>TBC</>}
			trendValue={trendRate.data}
			header={<>Loss Ratio</>}
			icon="account_balance_wallet"
			tooltipText=""
			tooltipTitle=""
			valueClassName="text-black"
			isLoading={value.isLoading || trendRate.isLoading}
		/>
	)
}

export default OverviewProtectGroupProfitValueSummary
