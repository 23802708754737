import {usePulseApi} from '../../../hooks/api/pulse'
import {filterState} from '../../../state/pulse'
import {useRecoilValue} from 'recoil'
import moment from 'moment'
import {ExportData} from '../../../types/pulse-data'
import {useExportData} from './useExportData'
import {selectedCoreCurrencyState, selectedGraphFiltersState} from '../../../state/pulse-filters'
import {graphFilterType, validCustomerTrendsGraphTypes} from '../../../types/pulse'
import {formatCurrency} from '../../../utils/currency'
import useGetGraphFilterLabel from './useGetGraphFilterLabel'
import {formatNumberMember} from '../../../utils/number'

export default function useExportCustomerTrendsChartData(): ExportData {
	const {
		getCustomerTrendsChartData
	} = usePulseApi()
	const filter = useRecoilValue(filterState)
	const graphFilters = useRecoilValue(selectedGraphFiltersState)
	const coreCurrencyCode = useRecoilValue(selectedCoreCurrencyState)
	const getGraphFilterLabel = useGetGraphFilterLabel()

	const getData = async (): Promise<any> => {
		const result = await getCustomerTrendsChartData(filter)
		const data: string[] = []

		if (!result.countries) {
			return data
		}

		for (let i = 0; i < result.countries.length; i++) {
			const country = result.countries[i]
			const record: any = {
				Country: country.countryName
			}

			for (const graphFilter in graphFilters) {
				const filterType = graphFilters[graphFilter]

				if (!validCustomerTrendsGraphTypes.some(x => x === filterType)) {
					continue
				}

				switch (filterType) {
					case graphFilterType.RefundProtectValue:
					case graphFilterType.GrossBookingValue:
					case graphFilterType.YourRevenue:
						record[getGraphFilterLabel(filterType)] =
							(country.chartData && formatCurrency(coreCurrencyCode, country.chartData[filterType], 0, 0)) || null
						break
					case graphFilterType.GrossBookingVolume:
					case graphFilterType.RefundProtectVolume:
						record[getGraphFilterLabel(filterType)] =
							(country.chartData && formatNumberMember(country.chartData[filterType])) || null
						break
					case graphFilterType.RevenuePerDollar:
						record[getGraphFilterLabel(filterType)] =
							(country.chartData && formatCurrency(coreCurrencyCode, country.chartData[filterType], 0, 4)) || null
						break
					case graphFilterType.AverageConversionRate:
						record[getGraphFilterLabel(filterType)] =
							country.chartData && `${country.chartData[filterType]}%`
						break
					default:
						record[getGraphFilterLabel(filterType)] =
							country.chartData && country.chartData[filterType]
				}
			}

			data.push(record)
		}

		return data
	}
	const getFileName = () => `CustomerTrendsChart-${moment().toISOString()}`
	const getHeaders = () => {
		const headers = ['Country']

		for (const filter in graphFilters) {
			const graphFilter = graphFilters[filter]

			if (!validCustomerTrendsGraphTypes.some(x => x === graphFilter)) {
				continue
			}

			headers.push(getGraphFilterLabel(graphFilter))
		}

		return headers
	}

	return useExportData({
		getData: getData,
		getFileName: getFileName,
		getHeaders: getHeaders
	})
}
