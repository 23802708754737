import React, {useEffect, useState} from 'react'
import DashboardPageLayout from './components/DashboardPageLayout'
import {useRecoilValue, useResetRecoilState} from 'recoil'
import {exportDataState, filterState} from '../../state/pulse'
import {signOut} from '../../services/user-service'
import DashboardSupport from './components/DashboardSupport'
import {settingsPageType} from '../../types/pulse'
import H2 from './components/Common/Headers/H2'
import classNames from 'classnames'
import AccountSection from './components/Common/Settings/AccountSection'
import RevenueShareSection from './components/Common/Settings/RevenueShareSection'

const PulseSettingsView = () => {
	const filter = useRecoilValue(filterState)
	const resetExportDataState = useResetRecoilState(exportDataState)
	const [settingsPage, setSettingsPage] = useState(settingsPageType.Account)
	const handleLogOut = async () => await signOut()

	useEffect(() => {
		resetExportDataState()
	}, [])

	useEffect(() => {
		if (filter.memberId) {
			setSettingsPage(settingsPageType.Account)
		}
	}, [filter])

	return (
		<DashboardPageLayout>
			<>
				<section className="
					max-w-6xl px-4 relative w-full
					lg:mx-auto lg:px-10"
				>
					<H2>Settings</H2>
					<div className="mb-8 inline-block">
						<div className="mb-4">
							<div
								className={classNames('inline-block mr-2 pr-2',
									'border-black border-r',
									'text-sm uppercase',
									'cursor-pointer',
									'hover:text-pulse-blue', {
										'text-pulse-blue': settingsPage === settingsPageType.Account
									})}
								onClick={() => setSettingsPage(settingsPageType.Account)}
							>
								Account
							</div>
							{/*{supportDetail.isFacilitator && (*/}
							{/*	<div*/}
							{/*		className={classNames('inline-block mr-2 pr-2',*/}
							{/*			'border-black border-r',*/}
							{/*			'text-sm uppercase',*/}
							{/*			'cursor-pointer',*/}
							{/*			'hover:text-pulse-blue', {*/}
							{/*				'text-pulse-blue': settingsPage === settingsPageType.RevenueShare*/}
							{/*			})}*/}
							{/*		onClick={() => setSettingsPage(settingsPageType.RevenueShare)}*/}
							{/*	>*/}
							{/*		Revenue Share*/}
							{/*	</div>*/}
							{/*)}*/}
							<div
								className="
									inline-block
									cursor-pointer
									hover:text-pulse-blue"
								onClick={handleLogOut}
							>
								<span className="
									align-middle mb-1 mr-1
									text-xl
									material-symbols-outlined"
								>
									logout
								</span>
								<span className="text-sm uppercase">Logout</span>
							</div>
						</div>
						{settingsPage === settingsPageType.Account && (
							<AccountSection/>
						)}
						{settingsPage === settingsPageType.RevenueShare && (
							<RevenueShareSection/>
						)}
					</div>
					<div>
						<DashboardSupport/>
					</div>
				</section>
			</>
		</DashboardPageLayout>
	)
}

export default PulseSettingsView
