import React, {ReactElement} from 'react'
import SecondaryValueContent from './SecondaryValueContent'

interface SecondaryValueCardProps {
	header: string,
	tooltipText: string,
	value: ReactElement,
	isLoading: boolean
}

const SecondaryValueCard = (props: SecondaryValueCardProps) => {
	return (
		<div className="
			flex flex-col h-full px-5 py-3 relative
			bg-white
			border border-gray-200 rounded-lg"
		>
			<SecondaryValueContent
				value={props.value}
				isLoading={props.isLoading}
				tooltipText={props.tooltipText}
				header={props.header}
			/>
		</div>
	)
}

export default SecondaryValueCard
