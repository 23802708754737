import React from 'react'
import {useRecoilState, useRecoilValue} from 'recoil'
import {
	verticalsPageVerticalState,
	verticalsState
} from '../../../../../state/pulse'
import {Listbox, ListboxButton, ListboxOption, ListboxOptions} from '@headlessui/react'

const VerticalsVerticalDropdown = () => {
	const verticals = useRecoilValue(verticalsState)
	const [vertical, setVertical] =
		useRecoilState(verticalsPageVerticalState)

	return (
		<Listbox value={vertical} onChange={(value) => setVertical(value)}>
			<ListboxButton>
				<div className="
					mb-2 pl-3 pr-2 py-[2px]
					bg-pulse-button-blue
					text-nowrap text-sm uppercase
					border border-pulse-icon-gray rounded-full
					cursor-pointer
					lg:mb-0"
				>
					<span className="leading-5 tracking-[1px]">
						{vertical === null ? 'All' : verticals.find(x => x.id === vertical)?.name}
					</span>
					<span className="
						align-middle ml-1
						leading-5
						material-symbols-outlined"
					>
						keyboard_arrow_down
					</span>
				</div>
			</ListboxButton>
			<ListboxOptions
				anchor="bottom start"
				className="
					min-w-[var(--button-width)] ml-4 mt-[5px] py-2 w-11/12 z-50
					bg-white
					border border-pulse-popup-border-gray rounded-lg
					lg:w-auto lg:ml-0"
			>
				<ListboxOption
					className="
						px-4 py-2
						text-sm text-black tracking-[1px] uppercase
						cursor-pointer
						data-[focus]:bg-pulse-button-blue"
					value={null}
				>
					All
				</ListboxOption>
				{verticals && verticals.map(vertical => (
					<ListboxOption
						className="
							px-4 py-2
							text-sm text-black tracking-[1px] uppercase
							cursor-pointer
							data-[focus]:bg-pulse-button-blue"
						key={vertical.id}
						value={vertical.id}
					>
						{vertical.name}
					</ListboxOption>
				))}
			</ListboxOptions>
		</Listbox>
	)
}

export default VerticalsVerticalDropdown
