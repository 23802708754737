import React from 'react'
import {usePulseApi} from '../../../hooks/api/pulse'
import {useApiValue} from '../../../hooks/api'
import LoadingSpinner from './Common/Spinner/LoadingSpinner'
import {CardColumn, CardHeader, TableColumn, TableHeader} from './Common/TableElements'
import moment from 'moment'
import {fullDateTimeFormat, getDuration} from '../../../utils/date'
import PageAnalyticsPager from './Common/PageAnalytics/PageAnalyticsPager'
import {filterState, pageAnalyticsTablePageState, tablePageSizeState} from '../../../state/pulse'
import {useRecoilValue} from 'recoil'

const PageAnalyticsView = () => {
	const {getPageAnalytics} = usePulseApi()
	const tableValue = useApiValue(filterState, getPageAnalytics, {
		analytics: []
	})
	const page = useRecoilValue(pageAnalyticsTablePageState)
	const pageSize = useRecoilValue(tablePageSizeState)
	const paginate = () => tableValue.data?.analytics?.slice((page - 1) * pageSize, page * pageSize) || []

	return (
		<>
			<LoadingSpinner
				className="flex items-center justify-center min-h-60 w-full"
				isLoading={tableValue.isLoading}
			>
				<>
					<div className="
						w-full
						text-sm
						lg:hidden"
					>
						{tableValue.data?.analytics && paginate()
							.map((row, index) => (
								<div
									className="flex items-center mb-4 space-x-4"
									key={index}
								>
									<div className="
										gap-3 grid grid-cols-[175px_auto] pb-4 w-full
										border-b border-gray-200"
									>
										<CardHeader
											title="Username"
										/>
										<CardColumn
											value={<span className="align-middle mr-3">{row.userName}</span>}
										/>
										<CardHeader
											title="Partner"
										/>
										<CardColumn
											value={<span className="align-middle mr-3">{row.memberName}</span>}
										/>
										<CardHeader
											title="Login"
										/>
										<CardColumn
											value={<span className="align-middle mr-3">{moment(row.sessionDateTimeUtc).format(fullDateTimeFormat)}</span>}
										/>
										<CardHeader
											title="Last Activity"
										/>
										<CardColumn
											value={<span className="align-middle mr-3">{moment(row.dateTimeEndedUtc).format(fullDateTimeFormat)}</span>}
										/>
										<CardHeader
											title="Duration"
										/>
										<CardColumn
											value={
												<span className="align-middle mr-3">
													{getDuration(moment(row.dateTimeEndedUtc).diff(moment(row.sessionDateTimeUtc)))}
												</span>
											}
										/>
									</div>
								</div>
						))}
					</div>
					<table className="
						hidden mb-4 table-fixed w-full
						text-sm
						lg:table"
					>
						<thead>
						<tr className="
							mb-2
							border-b border-gray-200"
						>
							<TableHeader className="w-[14%]" title="Username"/>
							<TableHeader className="w-[14%]" title="Partner"/>
							<TableHeader className="w-[14%]" title="Login"/>
							<TableHeader className="w-[14%]" title="Last Activity"/>
							<TableHeader className="w-[14%]" title="Duration"/>
						</tr>
						</thead>
						<tbody>
						{tableValue.data?.analytics && paginate()
							.map((row, index) => (
								<tr
									className="border-b border-gray-200"
									key={index}
								>
									<TableColumn
										value={<>{row.userName}</>}
									/>
									<TableColumn
										value={<>{row.memberName}</>}
									/>
									<TableColumn
										value={<>{moment(row.sessionDateTimeUtc).format(fullDateTimeFormat)}</>}
									/>
									<TableColumn
										value={<>{moment(row.dateTimeEndedUtc).format(fullDateTimeFormat)}</>}
									/>
									<TableColumn
										value={
											<>{getDuration(moment(row.dateTimeEndedUtc).diff(moment(row.sessionDateTimeUtc)))}</>
										}
									/>
								</tr>
						))}
						</tbody>
					</table>
					<PageAnalyticsPager data={tableValue.data}/>
				</>
			</LoadingSpinner>
		</>
	)
}

export default PageAnalyticsView
