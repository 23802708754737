import React from 'react'
import SecondaryValueCard from './Common/Cards/SecondaryValueCard'
import {usePulseApi} from '../../../hooks/api/pulse'
import {useApiValue} from '../../../hooks/api'
import {filterState} from '../../../state/pulse'
import {useRecoilValue} from 'recoil'
import {selectedCoreCurrencyNameState} from '../../../state/pulse-filters'
import useGetGraphFilterLabel from '../hooks/useGetGraphFilterLabel'
import {graphFilterType} from '../../../types/pulse'
import {Currency} from './Common/FormatElements'
import {FilterStateProps} from '../../../types/pulse-data'

const OverviewRevenuePerCurrencySummary = (props: FilterStateProps) => {
	const {getTransactionRevenuePerDollar} = usePulseApi()
	const value = useApiValue(props.filterState || filterState, getTransactionRevenuePerDollar, 0)
	const coreCurrencyName = useRecoilValue(selectedCoreCurrencyNameState)
	const getGraphFilterLabel = useGetGraphFilterLabel()

	return (
		<SecondaryValueCard
			value={<Currency value={value.data} max={4}/>}
			header={getGraphFilterLabel(graphFilterType.RevenuePerDollar)}
			tooltipText={`This is a performance metric that measures the amount of revenue generated for every ${coreCurrencyName} spent on Refund Protect fees.`}
			isLoading={value.isLoading}
		/>
	)
}

export default OverviewRevenuePerCurrencySummary
