import {selector} from 'recoil'
import {loggedInUserState} from './auth'
import {AnalyticsFilter} from '../types/analytics'

export type ValuePiece = Date | null;
export type Value = ValuePiece | [ValuePiece, ValuePiece];

const keyPrefix = 'analytics'

export const filterState = selector<AnalyticsFilter>({
	key: `${keyPrefix}-filter`,
	get: ({get}) => {
		return {
			userName: get(loggedInUserState),
			page: ''
		}
	}
})