import {useRecoilValue} from 'recoil'
import {userDetailState} from '../../../../../state/pulse'
import {getSsoUrl} from '../../../../../services/user-service'
import H4 from '../Headers/H4'
import React from 'react'
import DefaultButton from '../Buttons/DefaultButton'

const AccountSection = () => {
	const userDetail = useRecoilValue(userDetailState)
	const handleEdit = () => window.location.href = `${getSsoUrl()}Account/ChangePassword` ?? '#'

	return (
		<div className="
			min-w-72 p-6
			border border-gray-200 rounded-lg"
		>
			<div className="mb-4">
				<H4>Company</H4>
				<div>{userDetail.memberName}</div>
			</div>
			{userDetail.name && (
				<div className="mb-4">
					<H4>Name</H4>
					<div>{userDetail.name}</div>
				</div>
			)}
			{userDetail.userName && (
				<div className="mb-4">
					<H4>User Name</H4>
					<div>{userDetail.userName}</div>
				</div>
			)}
			<div className="mb-4">
				<H4>Password</H4>
				<div>***********</div>
			</div>
			<div className="flex flex-col items-end">
				<DefaultButton text="Edit" onClick={handleEdit}/>
			</div>
		</div>)
}

export default AccountSection