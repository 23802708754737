import React, {ReactNode} from 'react'
import classNames from 'classnames'

interface SecondaryCardTabProps extends React.HTMLAttributes<HTMLDivElement> {
	children: ReactNode
}

const SecondaryCardTab = (props: SecondaryCardTabProps) => {
	return (
		<div className={classNames('flex flex-col h-full',
			'border border-gray-200 rounded-lg',
			props.className)}
		>
			<div className={classNames('divide-y-2 divide-gray-200 space-y-2 grid grid-cols-1',
				'h-full pr-2 px-5 py-3 relative',
				'rounded-lg',
				'[&>*:nth-child(2)]:pt-5')}
			>
				{props.children}
			</div>
		</div>
	)
}

export default SecondaryCardTab
