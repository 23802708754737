import {
	PulseFilter,
	PulsePartnersRevenueTableFilter,
	PulseTableFilter,
	PulseTransactionFilter,
	PulseUserFilter,
	PulseVerticalsRevenueTableFilter
} from '../../types/pulse'
import {useApiActions} from '../api'
import {
	FacilitatorDetailDataModel,
	Product,
	PulseChartDataModel,
	PulseMapDataModel,
	PulsePageAnalyticsTableData,
	PulsePartnersRevenueTableDataModel,
	PulseTableDataModel,
	PulseVerticalsRevenueTableDataModel,
	SalesRegion,
	SupportDetail,
	TransactionTableData,
	TrustPilotReview,
	UserDetail,
	Vertical
} from '../../types/pulse-data'

export const usePulseApi = () => {
	const {callApi} = useApiActions('pulse')

	const getAverageConversionRate = async (filter: PulseFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'average-conversion-rate'
		})
	}

	const getAverageTransactionRate = async (filter: PulseFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'average-transaction-rate'
		})
	}

	const getAverageTransactionRevenue = async (filter: PulseFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'average-transaction-revenue'
		})
	}

	const getBookingValueSummary = async (filter: PulseFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'booking-value-summary'
		})
	}

	const getBookingVolumeSummary = async (filter: PulseFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'booking-volume-summary'
		})
	}

	const getBookingVolumeTrend = async (filter: PulseFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'booking-volume-trend'
		})
	}

	const getBookingRateTrend = async (filter: PulseFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'booking-rate-trend'
		})
	}

	const getProfitValueSummary = async (filter: PulseFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'profit-value-summary'
		})
	}

	const getProfitRateTrend = async (filter: PulseFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'profit-rate-trend'
		})
	}

	const getRefundProtectValueSummary = async (filter: PulseFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'refund-protect-value-summary'
		})
	}

	const getRefundProtectVolumeSummary = async (filter: PulseFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'refund-protect-volume-summary'
		})
	}

	const getRefundProtectVolumeTrend = async (filter: PulseFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'refund-protect-volume-trend'
		})
	}

	const getRefundProtectRateTrend = async (filter: PulseFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'refund-protect-rate-trend'
		})
	}

	const getTransactionRevenuePerDollar = async (filter: PulseFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'transaction-revenue-per-dollar'
		})
	}

	const getSalesRegions = async (filter: PulseFilter): Promise<SalesRegion[]> => {
		return await callApi<SalesRegion[], string>({
			query: filter,
			path: 'sales-regions'
		})
	}

	const getUserDetail = async (filter: PulseUserFilter): Promise<UserDetail> => {
		return await callApi<UserDetail, string>({
			query: filter,
			path: 'user-detail'
		})
	}

	const getAverageRefundHandleTime = async (filter: PulseFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'average-refund-handle-time'
		})
	}

	const getRefundAutoApproveRate = async (filter: PulseFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'refund-auto-approve-rate'
		})
	}

	const getAverageEmailVolume = async (filter: PulseFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'average-email-volume'
		})
	}

	const getAverageTrustPilotScore = async (filter: PulseFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'average-trustpilot-score'
		})
	}

	const getTrustPilotReviews = async (filter: PulseFilter): Promise<TrustPilotReview[]> => {
		return await callApi<TrustPilotReview[], string>({
			query: filter,
			path: 'trustpilot-reviews'
		})
	}

	const getTransactions = async (filter: PulseTableFilter): Promise<TransactionTableData> => {
		return await callApi<TransactionTableData, string>({
			query: filter,
			path: 'transactions'
		})
	}

	const getTransactionsVolumeSummary = async (filter: PulseTableFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'transactions-volume-summary'
		})
	}

	const getRevenueTrendsChartData = async (filter: PulseFilter): Promise<PulseTableDataModel> => {
		return await callApi<PulseTableDataModel, string>({
			query: filter,
			path: 'revenue-trends-chart-data'
		})
	}

	const getCustomerTrendsMapData = async (filter: PulseFilter): Promise<PulseMapDataModel> => {
		return await callApi<PulseMapDataModel, string>({
			query: filter,
			path: 'customer-trends-map-data'
		})
	}

	const getCustomerTrendsChartData = async (filter: PulseFilter): Promise<PulseChartDataModel> => {
		return await callApi<PulseChartDataModel, string>({
			query: filter,
			path: 'customer-trends-chart-data'
		})
	}

	const deleteTransactions = async (filter: PulseTransactionFilter): Promise<any> => {
		return await callApi<PulseChartDataModel, string>({
			query: filter,
			method: 'DELETE',
			path: 'transaction'
		})
	}

	const getProtectionValueSummary = async (filter: PulseFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'protection-value-summary'
		})
	}

	const getProtectionValueTrend = async (filter: PulseFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'protection-value-trend'
		})
	}

	const getSupportDetail = async (filter: PulseFilter): Promise<SupportDetail> => {
		return await callApi<SupportDetail, string>({
			query: filter,
			path: 'support-detail'
		})
	}

	const getApprovedRefundConversionRate = async (filter: PulseFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'approved-refund-conversion-rate'
		})
	}

	const getApprovedRefundRateTrend = async (filter: PulseFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'approved-refund-rate-trend'
		})
	}

	const getApprovedRefundValueSummary = async (filter: PulseFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'approved-refund-value-summary'
		})
	}

	const getApprovedRefundVolumeSummary = async (filter: PulseFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'approved-refund-volume-summary'
		})
	}

	const getApprovedRefundVolumeTrend = async (filter: PulseFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'approved-refund-volume-trend'
		})
	}

	const getAverageApprovedRefundValue = async (filter: PulseFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'average-approved-refund-value'
		})
	}

	const getDeclinedRefundConversionRate = async (filter: PulseFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'declined-refund-conversion-rate'
		})
	}

	const getDeclinedRefundRateTrend = async (filter: PulseFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'declined-refund-rate-trend'
		})
	}

	const getDeclinedRefundValueSummary = async (filter: PulseFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'declined-refund-value-summary'
		})
	}

	const getDeclinedRefundVolumeSummary = async (filter: PulseFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'declined-refund-volume-summary'
		})
	}

	const getDeclinedRefundVolumeTrend = async (filter: PulseFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'declined-refund-volume-trend'
		})
	}

	const getMaxRefundValue = async (filter: PulseFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'max-refund-value'
		})
	}

	const getRefundRateTrend = async (filter: PulseFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'refund-rate-trend'
		})
	}

	const getRefundValueSummary = async (filter: PulseFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'refund-value-summary'
		})
	}

	const getRefundVolumeSummary = async (filter: PulseFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'refund-volume-summary'
		})
	}

	const getRefundVolumeTrend = async (filter: PulseFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'refund-volume-trend'
		})
	}

	const getTopApprovedRefundReasons = async (filter: PulseFilter): Promise<string[]> => {
		return await callApi<string[], string>({
			query: filter,
			path: 'top-approved-refund-reasons'
		})
	}

	const getTopDeclinedRefundReasons = async (filter: PulseFilter): Promise<string[]> => {
		return await callApi<string[], string>({
			query: filter,
			path: 'top-declined-refund-reasons'
		})
	}

	const getPartnersRevenueTableData = async (filter: PulsePartnersRevenueTableFilter): Promise<PulsePartnersRevenueTableDataModel> => {
		return await callApi<PulsePartnersRevenueTableDataModel, string>({
			query: filter,
			path: 'partners-revenue-table-data'
		})
	}

	const getVerticalsRevenueTableData = async (filter: PulseVerticalsRevenueTableFilter): Promise<PulseVerticalsRevenueTableDataModel> => {
		return await callApi<PulseVerticalsRevenueTableDataModel, string>({
			query: filter,
			path: 'verticals-revenue-table-data'
		})
	}

	const getProtectGroupProfitValueSummary = async (filter: PulseFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'protect-group-profit-value-summary'
		})
	}

	const getProtectGroupProfitTrend = async (filter: PulseFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'protect-group-profit-trend'
		})
	}

	const getLossRatioRate = async (filter: PulseFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'loss-ratio-rate'
		})
	}

	const getLossRatioTrend = async (filter: PulseFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'loss-ratio-trend'
		})
	}

	const getFacilitatorDetail = async (filter: PulseFilter): Promise<FacilitatorDetailDataModel> => {
		return await callApi<FacilitatorDetailDataModel, string>({
			query: filter,
			path: 'facilitator-detail'
		})
	}

	const getProducts = async (filter: PulseFilter): Promise<Product[]> => {
		return await callApi<Product[], string>({
			query: filter,
			path: 'products'
		})
	}

	const getVerticals = async (filter: PulseFilter): Promise<Vertical[]> => {
		return await callApi<Vertical[], string>({
			query: filter,
			path: 'verticals'
		})
	}

	const getPageAnalytics = async (filter: PulseFilter): Promise<PulsePageAnalyticsTableData> => {
		return await callApi<PulsePageAnalyticsTableData, string>({
			query: filter,
			path: 'page-analytics'
		})
	}

	return {
		deleteTransactions,
		getApprovedRefundConversionRate,
		getApprovedRefundRateTrend,
		getApprovedRefundValueSummary,
		getApprovedRefundVolumeSummary,
		getApprovedRefundVolumeTrend,
		getAverageApprovedRefundValue,
		getAverageConversionRate,
		getAverageEmailVolume,
		getAverageRefundHandleTime,
		getAverageTransactionRate,
		getAverageTransactionRevenue,
		getAverageTrustPilotScore,
		getBookingRateTrend,
		getBookingValueSummary,
		getBookingVolumeSummary,
		getBookingVolumeTrend,
		getCustomerTrendsChartData,
		getCustomerTrendsMapData,
		getDeclinedRefundConversionRate,
		getDeclinedRefundRateTrend,
		getDeclinedRefundValueSummary,
		getDeclinedRefundVolumeSummary,
		getDeclinedRefundVolumeTrend,
		getFacilitatorDetail,
		getLossRatioRate,
		getLossRatioTrend,
		getMaxRefundValue,
		getPageAnalytics,
		getPartnersRevenueTableData,
		getProducts,
		getProfitRateTrend,
		getProfitValueSummary,
		getProtectGroupProfitValueSummary,
		getProtectGroupProfitTrend,
		getProtectionValueSummary,
		getProtectionValueTrend,
		getRefundAutoApproveRate,
		getRefundProtectRateTrend,
		getRefundProtectValueSummary,
		getRefundProtectVolumeSummary,
		getRefundProtectVolumeTrend,
		getRefundRateTrend,
		getRefundValueSummary,
		getRefundVolumeSummary,
		getRefundVolumeTrend,
		getRevenueTrendsChartData,
		getSalesRegions,
		getSupportDetail,
		getTopApprovedRefundReasons,
		getTopDeclinedRefundReasons,
		getTransactionRevenuePerDollar,
		getTransactions,
		getTransactionsVolumeSummary,
		getTrustPilotReviews,
		getUserDetail,
		getVerticals,
		getVerticalsRevenueTableData,
	}
}
