import {Line} from 'react-chartjs-2'
import React from 'react'
import {ChartData} from 'chart.js'
import {usePulseApi} from '../../../hooks/api/pulse'
import {useApiValue} from '../../../hooks/api'
import {overviewChartFilterState} from '../../../state/pulse'
import LoadingSpinner from './Common/Spinner/LoadingSpinner'
import useGetOverviewChartData from '../hooks/useGetOverviewChartData'
import {FilterStateProps} from '../../../types/pulse-data'

const OverviewGraphChart = (props: FilterStateProps) => {
	const {getRevenueTrendsChartData} = usePulseApi()
	const value = useApiValue(props.filterState || overviewChartFilterState, getRevenueTrendsChartData, {
		labels: []
	})
	const chartData = useGetOverviewChartData(value)

	const revenueConversionRateData: ChartData<'line'> = {
		labels: chartData.getLabels(),
		datasets: chartData.getRevenueConversionRateDataset()
	}

	const volumeData: ChartData<'line'> = {
		labels: chartData.getLabels(),
		datasets: chartData.getVolumeDataset()
	}

	return (
		<LoadingSpinner
			isLoading={value.isLoading}
			className="flex items-center justify-center min-h-44 w-full"
		>
			<div className="lg:flex lg:space-x-4">
				<div className="
					flex-1 p-5 mb-4
					bg-white
					border border-gray-200 rounded-lg
					lg:mb-0"
				>
					<div className="text-pulse-label-gray text-xs">
						Revenue &amp; Conversion Rate
					</div>
					<Line
						className="max-w-full"
						data={revenueConversionRateData}
						options={chartData.getRevenueConversionRateOptions()}
					/>
				</div>
				<div className="
					flex-1 p-5 mb-4
					bg-white
					border border-gray-200 rounded-lg
					lg:mb-0"
				>
					<div className="text-pulse-label-gray text-xs">
						Gross Booking Volume &amp; Refund Protect Volume
					</div>
					<Line
						className="max-w-full"
						data={volumeData}
						options={chartData.getVolumeOptions()}
					/>
				</div>
			</div>
		</LoadingSpinner>
	)
}

export default OverviewGraphChart
