import React, {ReactNode, useEffect, useState} from 'react'
import {
	exportDataState,
	memberTransactionsOutOfRangeState,
	titleSuffixState
} from '../../../state/pulse'
import {useRecoilValue, useSetRecoilState} from 'recoil'
import H2 from './Common/Headers/H2'
import CardTab from './Common/Cards/CardTab'
import OverviewProfitValueSummary from './OverviewProfitValueSummary'
import OverviewProtectionValueSummary from './OverviewProtectionValueSummary'
import OverviewRefundProtectValueSummary from './OverviewRefundProtectValueSummary'
import OverviewRefundProtectVolumeSummary from './OverviewRefundProtectVolumeSummary'
import OverviewBookingValueSummary from './OverviewBookingValueSummary'
import OverviewBookingVolumeSummary from './OverviewBookingVolumeSummary'
import DataAvailabilityWarningOverlay from './DataAvailabilityWarningOverlay'
import OverviewConversionRateSummary from './OverviewConversionRateSummary'
import OverviewPriceToCustomerSummary from './OverviewPriceToCustomerSummary'
import OverviewRevenuePerTransactionSummary from './OverviewRevenuePerTransactionSummary'
import OverviewRevenuePerCurrencySummary from './OverviewRevenuePerCurrencySummary'
import classNames from 'classnames'
import MinimizeIcon from './Common/Images/MinimizeIcon'
import MaximizeIcon from './Common/Images/MaximizeIcon'
import {ViewColumn, ViewGrid, ViewRow} from './Common/LayoutElements'
import OverviewApprovedRefundValueSummary from './OverviewApprovedRefundValueSummary'
import OverviewDeclinedRefundValueSummary from './OverviewDeclinedRefundValueSummary'
import OverviewRefundValueSummary from './OverviewRefundValueSummary'
import OverviewApprovedRefundVolumeSummary from './OverviewApprovedRefundVolumeSummary'
import OverviewDeclinedRefundVolumeSummary from './OverviewDeclinedRefundVolumeSummary'
import OverviewRefundVolumeSummary from './OverviewRefundVolumeSummary'
import OverviewDeclinedRefundConversionRateSummary from './OverviewDeclinedRefundConversionRateSummary'
import OverviewApprovedRefundConversionRateSummary from './OverviewApprovedRefundConversionRateSummary'
import OverviewTopApprovedRefundReasons from './OverviewTopApprovedRefundReasons'
import OverviewTopDeclinedRefundReasons from './OverviewTopDeclinedRefundReasons'
import ExportFilter from './Common/Filter/ExportFilter'
import useExportOverviewRefundsData from '../hooks/useExportOverviewRefundsData'
import OverviewTopPartnersTable from './OverviewTopPartnersTable'
import OverviewLeadershipRefundGraphChart from './OverviewLeadershipRefundGraphChart'
import OverviewProtectGroupProfitValueSummary from './OverviewProtectGroupProfitValueSummary'
import useExportOverviewLeadershipData from '../hooks/useExportOverviewLeadershipData'
import OverviewLossRatioValueSummary from './OverviewLossRatioValueSummary'
import OverviewGraphChart from './OverviewGraphChart'
import SecondaryCardTab from './Common/Cards/SecondaryCardTab'

const OverviewLeadershipView = () => {
	const [showRevenues, setShowRevenues] = useState(false)
	const [showRefunds, setShowRefunds] = useState(false)
	const exportData = useExportOverviewLeadershipData()
	const exportRefundsData = useExportOverviewRefundsData()
	const titleSuffix = useRecoilValue(titleSuffixState)
	const memberTransactionsOutOfRange = useRecoilValue(memberTransactionsOutOfRangeState)
	const setExportDataState = useSetRecoilState(exportDataState)
	const initialDelayMilliseconds = 1500

	useEffect(() => {
		setExportDataState(exportData)
	}, [exportData])

	return (
		<>
			<section className="
				max-w-6xl mb-4 px-4 relative w-full
				lg:mx-auto lg:px-10"
			>
				<H2>Overview - {titleSuffix}</H2>
				{memberTransactionsOutOfRange && (<DataAvailabilityWarningOverlay/>)}
			</section>
			{!memberTransactionsOutOfRange && (
				<>
					<section className="
						max-w-6xl mb-8 px-4 relative w-full
						lg:mx-auto lg:px-10"
					>
						<div className="
							grid grid-cols-1 gap-8 mb-4
							2md:grid-cols-[32%_auto]"
						>
							<div className="flex flex-col space-y-4">
								<div>
									<CardTab
										className="!bg-pulse-leadership-card-primary"
										header="Profit"
									>
										<OverviewProtectGroupProfitValueSummary/>
									</CardTab>
								</div>
								<div>
									<CardTab
										className="!bg-pulse-leadership-card-secondary"
										header="Loss Ratio"
										bodyClassName="bg-pulse-gray"
									>
										<OverviewLossRatioValueSummary/>
									</CardTab>
								</div>
								<div className="flex-1"></div>
							</div>
							<OverviewLeadershipRefundGraphChart/>
						</div>
					</section>
					<Delayed wait={initialDelayMilliseconds * 2}>
						<section className="
							max-w-6xl mb-8 px-4 relative w-full
							lg:mx-auto lg:px-10"
						>
							<H2>Refund Protect</H2>
							<div className="relative">
								<div className="
									grid grid-cols-1 gap-4 mb-4
									2md:grid-cols-2 lg:grid-cols-3"
								>
									<CardTab
										className="2md:col-span-2 lg:col-span-1"
										bodyClassName="bg-pulse-highlight-blue"
										header="Revenue Share"
									>
										<OverviewProtectionValueSummary/>
										<OverviewProfitValueSummary/>
									</CardTab>
									<CardTab header="Refund Protect Sales">
										<OverviewRefundProtectValueSummary/>
										<OverviewRefundProtectVolumeSummary/>
									</CardTab>
									<CardTab header="Total Sales">
										<OverviewBookingValueSummary/>
										<OverviewBookingVolumeSummary/>
									</CardTab>
								</div>
								<CollapsableButton state={showRevenues} setState={setShowRevenues}/>
							</div>
							<ViewColumn className={classNames('max-h-0 mb-0 overflow-hidden w-full',
								'duration-200 ease-in-out transition-all', {
									'mb-4 !max-h-screen': showRevenues
								})}
							>
								{showRevenues && (
									<>
										<ViewGrid>
											<OverviewConversionRateSummary/>
											<OverviewPriceToCustomerSummary/>
											<OverviewRevenuePerTransactionSummary/>
											<OverviewRevenuePerCurrencySummary/>
										</ViewGrid>
										<OverviewGraphChart/>
									</>
								)}
							</ViewColumn>
						</section>
					</Delayed>
					<Delayed wait={initialDelayMilliseconds * 3}>
						<section className="
							max-w-6xl mb-8 px-4 relative w-full
							lg:mx-auto lg:px-10"
						>
							<div className="flex items-center space-x-2">
								<H2>Refunds</H2>
								<div className="mb-2">
									<ExportFilter exportData={exportRefundsData}/>
								</div>
							</div>
							<div className="relative">
								<div className="
									grid grid-cols-1 gap-4 mb-4
									2md:grid-cols-2 lg:grid-cols-3"
								>
									<CardTab
										className="2md:col-span-2 lg:col-span-1"
										bodyClassName="bg-pulse-highlight-blue"
										header="Approved Refunds"
									>
										<OverviewApprovedRefundValueSummary/>
										<OverviewApprovedRefundVolumeSummary/>
									</CardTab>
									<CardTab header="Declined Refunds">
										<OverviewDeclinedRefundValueSummary/>
										<OverviewDeclinedRefundVolumeSummary/>
									</CardTab>
									<CardTab header="Refund Applications">
										<OverviewRefundValueSummary/>
										<OverviewRefundVolumeSummary/>
									</CardTab>
								</div>
								<CollapsableButton state={showRefunds} setState={setShowRefunds}/>
							</div>
							<ViewRow className={classNames('max-h-0 mb-0 overflow-hidden w-full',
								'duration-200 ease-in-out transition-all', {
									'mb-4 !max-h-screen': showRefunds
								})}
							>
								{showRefunds && (
									<ViewRow>
										<ViewColumn>
											<SecondaryCardTab>
												<OverviewApprovedRefundConversionRateSummary/>
												<OverviewTopApprovedRefundReasons/>
											</SecondaryCardTab>
										</ViewColumn>
										<ViewColumn>
											<SecondaryCardTab>
												<OverviewDeclinedRefundConversionRateSummary/>
												<OverviewTopDeclinedRefundReasons/>
											</SecondaryCardTab>
										</ViewColumn>
									</ViewRow>
								)}
							</ViewRow>
						</section>
					</Delayed>
					<Delayed wait={initialDelayMilliseconds * 4}>
						<section className="
							max-w-6xl mb-8 px-4 relative w-full
							lg:mx-auto lg:px-10"
						>
							<div className="flex items-center space-x-2">
								<H2>Top Performing Partners</H2>
							</div>
							<OverviewTopPartnersTable/>
						</section>
					</Delayed>
				</>
			)}
		</>
	)
}

interface CollapsableButtonProps {
	setState: React.Dispatch<React.SetStateAction<boolean>>,
	state: boolean
}

const CollapsableButton = (props: CollapsableButtonProps) => {
	return (
		<div
			className="
				absolute -bottom-3 min-w-36 mx-0 px-2 py-1 right-0
				bg-white
				text-xs tracking-[1px] uppercase
				border border-pulse-icon-gray rounded-full
				cursor-pointer
				hover:opacity-60"
			onClick={() => props.setState(!props.state)}
		>
			{props.state && (
				<>
					<MinimizeIcon/>
					<span className="align-middle ml-1">Hide Details</span>
				</>
			)}
			{!props.state && (
				<>
					<MaximizeIcon/>
					<span className="align-middle ml-1">Show Details</span>
				</>
			)}
		</div>
	)
}

const Delayed = (props: { children: ReactNode, wait: number }) => {
	const [hidden, setHidden] = useState(true)

	useEffect(() => {
		setTimeout(() => {
			setHidden(false);
		}, props.wait);
	})

	return (
		<div className={classNames('transition ease-in-out opacity-0', {
			'opacity-100': !hidden
		})}>
			{!hidden && (<>{props.children}</>)}
		</div>
	)
}

export default OverviewLeadershipView
