import React from 'react'
import {usePulseApi} from '../../../hooks/api/pulse'
import {useApiValue} from '../../../hooks/api'
import {filterState} from '../../../state/pulse'
import {Percentage} from './Common/FormatElements'
import SecondaryValueContent from './Common/Cards/SecondaryValueContent'

const OverviewApprovedRefundConversionRateSummary = () => {
	const {getApprovedRefundConversionRate} = usePulseApi()
	const value = useApiValue(filterState, getApprovedRefundConversionRate, 0)

	return (
		<div className="relative">
			<SecondaryValueContent
				value={<Percentage value={value.data}/>}
				header="Approved Refunds"
				tooltipText="Percentage of refund requests that were approved."
				isLoading={value.isLoading}
			/>
		</div>
	)
}

export default OverviewApprovedRefundConversionRateSummary
