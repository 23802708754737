import {useApiActions} from '../api'
import {AnalyticsFilter} from '../../types/analytics'

export const useAnalyticsApi = () => {
	const {callApi} = useApiActions('analytics')

	const trackPage = async (filter: AnalyticsFilter): Promise<string> => {
		return await callApi<string, string>({
			query: filter,
			path: 'track-page'
		})
	}

	return {
		trackPage
	}
}
