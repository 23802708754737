import {PulsePageAnalyticsTableData} from '../../../../../types/pulse-data'
import {useRecoilState, useRecoilValue} from 'recoil'
import {
	pageAnalyticsTablePageState,
	tablePageSizeState
} from '../../../../../state/pulse'
import React from 'react'
import classNames from 'classnames'

const PageAnalyticsPager = (props: { data: PulsePageAnalyticsTableData }) => {
	const [page, setPage] = useRecoilState(pageAnalyticsTablePageState)
	const pageSize = useRecoilValue(tablePageSizeState)
	const totalPages = Math.floor((props.data.count || 0) / pageSize) + 1

	const handlePageChange = (page: number) => setPage(page)
	const showPage = (targetPage: number) =>
		targetPage === 1 ||
		targetPage === page - 1 ||
		(targetPage >= page && targetPage <= page + 3)

	return (
		<>
			{totalPages > 1 && (
				<div className="mb-8 flex">
					<div className={classNames('p-2',
						'tracking-[1px]',
						'cursor-pointer', {
							'text-pulse-icon-gray': page === 1
						})} onClick={() => handlePageChange(1)}>1
					</div>
					{page >= 4 && (
						<div className="leading-10 tracking-[1px]">...</div>
					)}
					{Array.from({length: totalPages - 2}, (_, i) => i + 2)
						.map((_, index) => (
							<>
								{showPage(index + 2) && (
									<div key={index}
									     className={classNames('p-2',
										     'tracking-[1px]',
										     'cursor-pointer', {
											     'text-pulse-icon-gray': page === index + 2
										     })}
									     onClick={() =>
										     handlePageChange(index + 2)
									     }>{index + 2}</div>
								)}
							</>
						))
					}
					{totalPages > 3 && page < totalPages - 4 && (
						<div className="leading-10 tracking-[1px]">...</div>
					)}
					<div className={classNames('p-2',
						'tracking-[1px]',
						'cursor-pointer', {
							'text-pulse-icon-gray': page === totalPages
						})} onClick={() => handlePageChange(totalPages)}>{totalPages}
					</div>
					{page > 1 && (
						<div className="flex items-center ml-4">
							<div
								className="
									mb-2 pl-1 pr-3 py-[2px]
									bg-white
									border border-pulse-icon-gray rounded-full
									text-nowrap text-sm uppercase
									cursor-pointer
									lg:mb-0"
								onClick={() => handlePageChange(page - 1)}
							>
								<span className="
									align-middle mr-1
									leading-5
									material-symbols-outlined"
								>
									chevron_left
								</span>
								<span className="align-middle leading-5 tracking-[1px]">Previous</span>
							</div>
						</div>
					)}
					{page < totalPages && (
						<div className="ml-4 flex items-center">
							<div
								className="
									mb-2 pl-3 pr-1 py-[2px]
									bg-white
									text-nowrap text-sm uppercase
									border border-pulse-icon-gray rounded-full
									cursor-pointer
									lg:mb-0"
								onClick={() => handlePageChange(page + 1)}
							>
								<span className="align-middle leading-5 tracking-[1px]">Next</span>
								<span className="
									align-middle ml-1
									leading-5
									material-symbols-outlined"
								>
									chevron_right
								</span>
							</div>
						</div>
					)}
				</div>
			)}
		</>
	)
}

export default PageAnalyticsPager
