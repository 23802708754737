import {filterState} from '../../../../../state/pulse'
import React, {useState} from 'react'
import {usePulseApi} from '../../../../../hooks/api/pulse'
import {useApiValue} from '../../../../../hooks/api'
import LoadingSpinner from '../Spinner/LoadingSpinner'
import {Listbox, ListboxButton, ListboxOption, ListboxOptions} from '@headlessui/react'
import {FacilitatorDetailMemberDataModel} from '../../../../../types/pulse-data'

const RevenueShareSection = () => {
	const {getFacilitatorDetail} = usePulseApi()
	const value = useApiValue(filterState, getFacilitatorDetail, {})
	const [selectedMember, setSelectedMember] = useState<FacilitatorDetailMemberDataModel | null>(null)

	const handleClick = (memberId: number) => {
		setSelectedMember(value.data.clients?.find(x => x.memberId === memberId) || null)
	}

	return (
		<div className="
			min-w-72 p-6
			border border-gray-200 rounded-lg"
		>
			<LoadingSpinner isLoading={value.isLoading}>
				<>
					{!value.data.clients && (<p>No data available</p>)}
					{value.data.clients && (<>
						<Listbox value={selectedMember?.memberId} onChange={(value) => handleClick(value)}>
							<ListboxButton>
								<div className="
									mb-4 pl-3 pr-2 py-[2px]
									bg-white
									text-nowrap text-sm uppercase
									border border-pulse-icon-gray rounded-full
									cursor-pointer
									lg:mb-0"
								>
								<span className="leading-5 tracking-[1px]">
									{selectedMember && selectedMember.memberName}
									{!selectedMember && 'Select Partner'}
								</span>
									<span className="
										align-middle ml-1
										leading-5
										material-symbols-outlined"
									>
										keyboard_arrow_down
									</span>
								</div>
							</ListboxButton>
							<ListboxOptions
								className="
									min-w-[var(--button-width)] ml-4 mt-[5px] py-2 w-11/12 z-50
									bg-white
									border border-pulse-popup-border-gray rounded-lg
									lg:w-auto lg:ml-0"
								anchor="bottom start"
							>
								{value.data.clients?.map((client, key) => (
									<ListboxOption
										id={`${key}`}
										value={client.memberId}
										className="
											px-4 py-2
											text-sm text-black tracking-[1px] uppercase
											cursor-pointer
											data-[focus]:bg-pulse-button-blue"
									>
										{client.memberName}
									</ListboxOption>
								))}
							</ListboxOptions>
						</Listbox>
						{selectedMember && selectedMember.rates?.map((rate, rateIndex) => (
							<div key={rateIndex} className="flex flex-col mb-4 mt-8 space-y-4">
								<div className="
									flex pb-4 space-x-4
									border-b border-gray-200"
								>
									<div className="font-medium">
										<div>{selectedMember.memberName}</div>
										<div>
											<span>Revenue Share</span>
										</div>
									</div>
									<div className="flex-1 text-right">
										{selectedMember.clientRevenue || 0}%
									</div>
								</div>
								<div className="
									flex pb-4 space-x-4
									border-b border-gray-200"
								>
									<div className="font-medium">Our Revenue Share</div>
									<div className="flex-1 text-right">
										{100 - (selectedMember.clientRevenue || 0) - (rate.ratePercentage || 0)}%
									</div>
								</div>
								<div className="flex space-x-4">
									<div className="font-medium">Protect Group Revenue Share</div>
									<div className="flex-1 text-right">
										{rate.ratePercentage}%
									</div>
								</div>
							</div>
						))}
					</>)}
				</>
			</LoadingSpinner>
		</div>)
}

export default RevenueShareSection