import GraphFilterCard from './Common/GraphFilterCard/GraphFilterCard'
import React from 'react'
import {
	graphRevenue,
	graphValueTrends,
	graphVolumeTrends
} from '../../../types/pulse'
import {usePulseApi} from '../../../hooks/api/pulse'
import {useApiValue} from '../../../hooks/api'
import {filterState, titleSuffixState} from '../../../state/pulse'
import LoadingSpinner from './Common/Spinner/LoadingSpinner'
import WorldMap from './Common/Map/WorldMap'
import useGetCustomerTrendsMapData from '../hooks/useGetCustomerTrendsMapData'
import useGetCustomerTrendsChartData from '../hooks/useGetCustomerTrendsChartData'
import Tooltip from './Common/Tooltip/Tooltip'
import ExportFilter from './Common/Filter/ExportFilter'
import useExportCustomerTrendsChartData from '../hooks/useExportCustomerTrendsChartData'
import UpsellOverlay from './UpsellOverlay'
import {useRecoilValue} from 'recoil'
import H4 from './Common/Headers/H4'
import classNames from 'classnames'

const CustomerTrendsGraph = () => {
	const {getCustomerTrendsChartData, getCustomerTrendsMapData} = usePulseApi()
	const mapValue = useApiValue(filterState, getCustomerTrendsMapData, {
		countries: {}
	})
	const chartValue = useApiValue(filterState, getCustomerTrendsChartData, {
		countries: []
	})
	const mapData = useGetCustomerTrendsMapData(mapValue)
	const chartData = useGetCustomerTrendsChartData(chartValue)
	const exportChartData = useExportCustomerTrendsChartData()
	const chartTitles = chartData.getTitles()
	const chartRecords = chartData.getData()
	const chartColors = chartData.getColors()
	const titleSuffix = useRecoilValue(titleSuffixState)

	return (
		<div className="
			mb-0 space-x-3
			md:flex md:mb-4"
		>
			<div className="
				flex flex-col mb-4 min-w-64 p-4 space-y-3
				bg-pulse-gray rounded-lg
				md:mb-0"
			>
				<H4>Fields</H4>
				<GraphFilterCard title="Value Demographics" types={graphValueTrends}/>
				<GraphFilterCard title="Volume Demographics" types={graphVolumeTrends}/>
				<GraphFilterCard title="Revenue" types={graphRevenue}/>
			</div>
			<div className="flex-1">
				<h2 className="inline-block mb-2 relative">
					<span className="font-semibold text-lg uppercase">Customer Trends - {titleSuffix}</span>
					<Tooltip
						id={`customerTrends-popup`}
						tooltipTitle="Customer Trends"
						tooltipText="Revenue data viewed by customer location (dynamic integration only)."
						tooltipClassName="absolute -right-6 top-0"
					/>
				</h2>
				<LoadingSpinner
					isLoading={mapValue.isLoading}
					className="flex items-center justify-center min-h-96 w-full"
				>
					<div className="relative">
						<WorldMap mapData={mapData}/>
						{mapValue.data.upsellMember && (<UpsellOverlay/>)}
					</div>
				</LoadingSpinner>
				{chartTitles.length > 0 && (
					<LoadingSpinner
						isLoading={chartValue.isLoading}
						className="flex items-center justify-center min-h-60 w-full"
					>
						<>
							{chartRecords.length > 0 && (
								<>
									<div className="flex items-center mb-6 mt-12">
										<h2 className="
											flex-1 relative inline-block
											font-semibold text-lg"
										>
											Top Countries
										</h2>
										<div>
											<ExportFilter exportData={exportChartData}/>
										</div>
									</div>
									<table className="
										table-auto w-full
										text-sm"
									>
										<thead>
										<tr className="
											mb-2
											border-b-2 border-gray-200"
										>
											<th className="
												px-2 py-4
												font-medium text-left"
											>
												Country
											</th>
											{chartTitles.map((title, index) => (
												<th
													key={index}
													className="
														px-2 py-4
														font-normal text-left text-pulse-icon-gray"
												>
													{title}
												</th>
											))}
										</tr>
										</thead>
										<tbody>
										{chartRecords.map((row, index) => (
											<tr key={index} className="border-b border-gray-200">
												{row.map((value, index) => (
													<td
														key={index}
														className={classNames('p-2',
															'font-medium text-left',
															chartColors[index]
														)}
													>
														<>{value}</>
													</td>
												))}
											</tr>
										))}
										</tbody>
									</table>
								</>
							)}
						</>
					</LoadingSpinner>
				)}
			</div>
		</div>
	)
}

export default CustomerTrendsGraph
