import {usePulseApi} from '../../../hooks/api/pulse'
import {filterState} from '../../../state/pulse'
import {useRecoilValue} from 'recoil'
import moment from 'moment'
import {ExportData} from '../../../types/pulse-data'
import {useExportData} from './useExportData'
import {selectedCoreCurrencyState, selectedGraphFiltersState} from '../../../state/pulse-filters'
import {graphFilterType, validCustomerTrendsGraphTypes} from '../../../types/pulse'
import {formatCurrency} from '../../../utils/currency'
import useGetGraphFilterLabel from './useGetGraphFilterLabel'
import {formatNumberMember} from '../../../utils/number'

export default function useExportCustomerTrendsMapData(): ExportData {
	const {
		getCustomerTrendsMapData
	} = usePulseApi()
	const filter = useRecoilValue(filterState)
	const graphFilters = useRecoilValue(selectedGraphFiltersState)
	const coreCurrencyCode = useRecoilValue(selectedCoreCurrencyState)
	const getGraphFilterLabel = useGetGraphFilterLabel()

	const getData = async (): Promise<any> => {
		const result = await getCustomerTrendsMapData(filter)
		const data = []

		for (let countryCode in result.countries) {
			const country = result.countries[countryCode]

			const record: any = {
				Country: country.countryName
			}

			for (const graphFilter in graphFilters) {
				const filterType = graphFilters[graphFilter]

				if (!validCustomerTrendsGraphTypes.some(x => x === filterType)) {
					continue
				}

				let value: any | null

				switch (filterType) {
					case graphFilterType.RefundProtectValue:
						value = (country.refundProtectValue && formatCurrency(coreCurrencyCode, country.refundProtectValue, 0, 0)) || null
						break
					case graphFilterType.GrossBookingValue:
						value = (country.grossBookingValue && formatCurrency(coreCurrencyCode, country.grossBookingValue, 0, 0)) || null
						break
					case graphFilterType.YourRevenue:
						value = (country.yourRevenue && formatCurrency(coreCurrencyCode, country.yourRevenue, 0, 0)) || null
						break
					case graphFilterType.RevenuePerDollar:
						value = (country.revenuePerDollar && formatCurrency(coreCurrencyCode, country.revenuePerDollar, 0, 4)) || null
						break
					case graphFilterType.RefundProtectVolume:
						value = (country.refundProtectVolume && formatNumberMember(country.refundProtectVolume)) || null
						break
					case graphFilterType.GrossBookingVolume:
						value = (country.grossBookingVolume && formatNumberMember(country.grossBookingVolume)) || null
						break
					case graphFilterType.AverageConversionRate:
						value = country.averageConversionRate && `${country.averageConversionRate}%`
						break
				}

				record[getGraphFilterLabel(filterType)] = value
			}

			data.push(record)
		}

		return data
	}
	const getFileName = () => `CustomerTrendsMap-${moment().toISOString()}`
	const getHeaders = () => {
		const headers = ['Country']

		for (const filter in graphFilters) {
			const graphFilter = graphFilters[filter]

			if (!validCustomerTrendsGraphTypes.some(x => x === graphFilter)) {
				continue
			}

			headers.push(getGraphFilterLabel(graphFilter))
		}

		return headers
	}

	return useExportData({
		getData: getData,
		getFileName: getFileName,
		getHeaders: getHeaders
	})
}
