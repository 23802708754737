import React, {ReactElement} from 'react'
import {v4 as uuidv4} from 'uuid'
import LoadingSpinner from '../Spinner/LoadingSpinner'
import Tooltip from '../Tooltip/Tooltip'

interface SecondaryValueContentProps {
	header: string
	isLoading: boolean
	tooltipText: string
	value: ReactElement
}

const SecondaryValueContent = (props: SecondaryValueContentProps) => {
	const id = uuidv4()

	return (
		<LoadingSpinner
			isLoading={props.isLoading}
			className="h-full w-full"
		>
			<>
				<div className="
					flex-1 mb-1
					text-pulse-label-gray text-xs"
				>
					{props.header}
				</div>
				<div className="flex">
					<div className="flex-none mr-2">
						<div className="flex h-full items-center">
							<span className="
								mb-2
								text-2xl text-pulse-icon-gray
								material-symbols-outlined"
							>
								monitoring
							</span>
						</div>
					</div>
					<div>
						<div className="mb-1 text-2xl text-black">{props.value}</div>
						{props.tooltipText && (
							<Tooltip
								id={`${id}-popup`}
								tooltipTitle={props.header}
								tooltipText={props.tooltipText}
							/>
						)}
					</div>
				</div>
			</>
		</LoadingSpinner>
	)
}

export default SecondaryValueContent