import React, {useState} from 'react'
import {usePulseApi} from '../../../hooks/api/pulse'
import {useApiValue} from '../../../hooks/api'
import {overviewLeadershipTopPartnersTableFilterState} from '../../../state/pulse'
import LoadingSpinner from './Common/Spinner/LoadingSpinner'
import {CardColumn, CardHeader, TableColumn, TableHeader} from './Common/TableElements'
import MinimizeIcon from './Common/Images/MinimizeIcon'
import MaximizeIcon from './Common/Images/MaximizeIcon'
import {Currency} from './Common/FormatElements'
import PricingModelTag from './Common/Partners/PricingModelTag'
import {useNavigate} from 'react-router-dom'
import {dashboardPaths, Dashboards} from '../../../types'

const OverviewTopPartnersTable = () => {
	const {getPartnersRevenueTableData} = usePulseApi()
	const chartValue = useApiValue(overviewLeadershipTopPartnersTableFilterState, getPartnersRevenueTableData, {
		members: []
	})
	const [showRows, setShowRows] = useState(false)
	const navigate = useNavigate()
	const maxRecords = 5

	return (
		<LoadingSpinner
			isLoading={chartValue.isLoading}
			className="flex items-center justify-center min-h-60 w-full"
		>
			<>
				<div className="
					w-full
					text-sm
					lg:hidden"
				>
					{chartValue.data.members && chartValue.data.members
						.slice(0, showRows ? chartValue.data.members.length : maxRecords)
						.map((row, index) => (
							<div
								key={index}
								className="flex items-center mb-4 space-x-4"
							>
								<div className="
									gap-3 grid grid-cols-[175px_auto] pb-4 w-full
									border-b border-gray-200"
								>
									<CardHeader
										title="Partner"
									/>
									<CardColumn
										value=
											{
												<>
													{row.pricingModel && (<PricingModelTag tag={row.pricingModel}/>)}
													<span className="align-middle mr-3">{row.memberName}</span>
												</>
											}
									/>
									<CardHeader
										title="PG Revenue"
										className="text-pulse-blue"
									/>
									<CardColumn
										value={<Currency value={row.protectionValue}/>}
										className="text-pulse-blue"
									/>
									<CardHeader
										title="RP Sales"
									/>
									<CardColumn
										value={<Currency value={row.refundProtectValue}/>}
									/>
									<CardHeader
										title="Partner's Revenue"
									/>
									<CardColumn
										value={<Currency value={row.profitValue}/>}
									/>
									<CardHeader
										title="Paid Refunds"
										className="text-pulse-red"
									/>
									<CardColumn
										value={<Currency value={row.approvedRefundsValue}/>}
										className="text-pulse-red"
									/>
									<CardHeader
										title="Loss Ratio"
										className="text-pulse-orange"
									/>
									<CardColumn
										value={<>TBC</>}
										className="text-pulse-orange"
									/>
								</div>
							</div>
					))}
				</div>
				<table className="
					hidden mb-4 table-fixed w-full
					text-sm
					lg:table"
				>
					<thead>
					<tr className="
						mb-2
						border-b border-gray-200"
					>
						<TableHeader title="Partner"/>
						<TableHeader title="PG Revenue" className="text-pulse-blue w-[14%]"/>
						<TableHeader title="RP Sales" className="w-[14%]"/>
						<TableHeader title="Partner's Revenue" className="w-[14%]"/>
						<TableHeader title="Paid Refunds" className="text-pulse-red w-[14%]"/>
						<TableHeader title="Loss Ratio" className="text-pulse-orange w-[14%]"/>
					</tr>
					</thead>
					<tbody>
					{chartValue.data.members && chartValue.data.members
						.slice(0, showRows ? chartValue.data.members.length : maxRecords)
						.map((row, index) => (
							<tr
								key={index}
								className="border-b border-gray-200"
							>
								<TableColumn
									value={
										<>
											{row.pricingModel && (<PricingModelTag tag={row.pricingModel}/>)}
											<span className="align-middle mr-3">{row.memberName}</span>
										</>
									}
								/>
								<TableColumn
									value={<Currency value={row.protectionValue}/>}
									className="text-pulse-blue"
								/>
								<TableColumn
									value={<Currency value={row.refundProtectValue}/>}
								/>
								<TableColumn
									value={<Currency value={row.profitValue}/>}
								/>
								<TableColumn
									value={<Currency value={row.approvedRefundsValue}/>}
									className="text-pulse-red"
								/>
								<TableColumn
									value={<>TBC</>}
									className="text-pulse-orange"
								/>
							</tr>
					))}
					</tbody>
				</table>
				<div className="flex mb-4 ml-2 space-x-8">
					<div>
						<span className="
							align-middle h-3 inline-block mr-2 w-3
							bg-pulse-icon-gray opacity-60 rounded-full"
						/>
						<span className="
							align-middle
							text-sm"
						>
							Static
						</span>
					</div>
					<div>
						<span className="
							align-middle h-3 inline-block mr-2 w-3
							bg-pulse-green rounded-full"
						/>
						<span className="
							align-middle
							text-sm"
						>
							Dynamic
						</span>
					</div>
				</div>
				<div className="flex space-x-4">
					{chartValue.data.members && chartValue.data.members.length > maxRecords && (
						<div
							className="
								inline-block min-w-32 mx-0 px-3 py-1
								bg-white
								text-xs tracking-[1px] uppercase
								border border-pulse-icon-gray rounded-full
								cursor-pointer
								hover:opacity-60"
							onClick={() => setShowRows(!showRows)}
						>
							{showRows && (
								<>
									<MinimizeIcon/>
									<span className="align-middle ml-1">Show Less</span>
								</>
							)}
							{!showRows && (
								<>
									<MaximizeIcon/>
									<span className="align-middle ml-1">Show More</span>
								</>
							)}
						</div>
					)}
					<div
						className="
							inline-block mx-0 px-3 py-1
							bg-white
							text-xs tracking-[1px] uppercase
							border border-pulse-icon-gray rounded-full
							cursor-pointer
							hover:opacity-60"
						onClick={() => navigate(dashboardPaths[Dashboards.PulsePartners])}
					>
						<span className="align-middle">All Partners</span>
					</div>
				</div>
			</>
		</LoadingSpinner>
	)
}

export default OverviewTopPartnersTable
